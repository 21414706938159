import React, { Component } from "react"
import { graphql, Link, navigate } from "gatsby"
import { formatDate, categoriesStringToSlugsArray, changeToLocalLinks, createMetaDesc } from "../components/helpers/helpers";
import i18Data from '../../content/intl/labels.json';
import { getTranslations } from "../components/helpers/helpers";

import Layout from "../components/layout";
import SEO from "../components/seo";
import "../styles/blog.scss"
import Share from "../components/share";

class Blog extends Component {

    componentDidMount() {
        this.windowRef = window;
        this.documentRef = document;
        this.linksUnsubs = changeToLocalLinks(this.isHtml, navigate, [ i18Data.common.url, i18Data.common.altUrl ]);
    }

    componentWillUnmount() {
        if (this.isHtml) {
            this.isHtml.removeEventListener("click", this.linksUnsubs);
        }
    }

    render() {
        const { data: { pageData }, pageContext: { lang, prefix, translateKey, blogPageSlug, slug } } = this.props;
        const page = pageData.edges.filter(({ node }) => node.frontmatter.lang === lang)[ 0 ]?.node;
        const translations = getTranslations(pageData.edges, translateKey);
        const url = `${prefix}/${blogPageSlug}/${slug}`;
        const textDesc = page.frontmatter.summary || createMetaDesc(page.excerpt);
        const i18 = i18Data[ lang ];

        return (
            <Layout mainClassName="blog" data={{}} noActiveMenu={true} pageContext={this.props.pageContext} translations={translations}>

                <SEO title={page.frontmatter.title} description={textDesc} lang={lang ? lang : "en"} />

                <div
                    className="blog-container blog-post initial-padding"
                >

                    <div className="blog-top">
                        <h1 dangerouslySetInnerHTML={{ __html: page.frontmatter.title }}></h1>

                        <time className="entry-date" data-datetime={page.frontmatter.pubDate} pubdate="">{formatDate(new Date(page.frontmatter.pubDate), lang, false)}</time>

                    </div>

                    <div className="blog-article">

                        <div className="is-html max-width" 
                            dangerouslySetInnerHTML={{ __html: page.html }} 
                            ref={(t) => { this.isHtml = t }}></div>     

                        <div className="relations">
                            { page.frontmatter.categories &&
                            <div className="relations-section">
                                <h4>{i18.labels.categories}</h4>
                                <ul>
                                {categoriesStringToSlugsArray(page.frontmatter.categories).map(cat => {
                                    return(
                                        <li key={cat.slug}>
                                        <Link key={cat.slug} to={`${prefix}/${blogPageSlug}/tag/${cat.slug}`} rel="category tag">
                                            {cat.text}
                                        </Link>
                                        </li>
                                    )
                                })}
                                </ul>
                            </div>
                            }                            
                        </div>                   

                    </div>



                    <Share url={i18Data.common.url + url} lang={lang} title={page.frontmatter.title} />

                </div>

            </Layout>
        )
    }
}

export default Blog


export const query = graphql`
    query BlogPostQuery($translateKey: String!) {
        pageData: allMarkdownRemark(filter: { frontmatter: { template:{ eq: "blogPost" }, translateKey: { eq: $translateKey }} }) {
            edges {
                node {
                    frontmatter {
                        lang
                        template
                        slug
                        summary
                        title
                        pubDate
                        categories
                        translateKey
                    }
                    excerpt
                    html

                }
            }
        }        

    }
`